// Swiper slideshow library setup (first slideshow).
(() => {
	new Swiper('.index-slideshow', {
		// Optional parameters
		direction: 'horizontal',
		loop: true,
		autoHeight: true,

		// If we need pagination
		pagination: {
			el: '.index-slideshow .swiper-pagination',
		},

		// Navigation arrows
		navigation: {
			nextEl: '.index-slideshow .swiper-button-next',
			prevEl: '.index-slideshow .swiper-button-prev',
		},
	});
})();

// Swiper slideshow library setup (showcase slideshow)
(() => {
	new Swiper(".index-showcase-slideshow", {
		direction: "horizontal",
		loop: true,

		navigation: {
			nextEl: ".index-showcase-slideshow .swiper-button-next",
			prevEl: ".index-showcase-slideshow .swiper-button-prev",
			hideOnClick: true,
		}
	});
})();

// Intersection observer for animations.
// Observed elements have a "visible" class attached to them,
// which can then be styled with CSS.
document.addEventListener("DOMContentLoaded", () => {
	window.setTimeout(() => {
		const indexFeatureContainerEl = document.querySelector(".index-feature-container");

		const observer = new IntersectionObserver((entries, observer) => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					entry.target.classList.add("visible");
					observer.unobserve(indexFeatureContainerEl);
				}
			}
		}, {
			threshold: 0.3,
		});

		observer.observe(indexFeatureContainerEl);
	}, 1000);
});

document.addEventListener("DOMContentLoaded", () => {
	window.setTimeout(() => {
		const whyMakeThisEl = document.querySelector(".why-make-this-container");
		const ffTextEl = document.querySelector("#ff");

		// Note: larger-than-viewport entries may not trigger the callback.
		// https://github.com/w3c/IntersectionObserver/issues/124
		const observer = new IntersectionObserver((entries, observer) => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					entry.target.classList.add("visible");
				}
			}
		});

		observer.observe(whyMakeThisEl);
		observer.observe(ffTextEl);
	}, 1000);
});

(() => {
	(() => {
		const f1 = document.getElementById("f1");
		const length = f1.getTotalLength();
		f1.style.setProperty("--strokes", Math.ceil(length));
	})();

	(() => {
		const f2 = document.getElementById("f2");
		const length = f2.getTotalLength();
		f2.style.setProperty("--strokes", Math.ceil(length));
	})();
})();